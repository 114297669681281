<template>
  <div>
    <b-container>
      <div class="d-flex flex-column justify-content-center">
        <b-col class="text-left text-light">
          <br /><br /><br /><br /><br /><br />

          <h1 class="font-weight-light d-block mx-auto text-center mb-5">
            Our Story
          </h1>

          <p class="font-weight-lighter">
            Uniquely Trackd has a heritage in music.
          </p>

          <p class="font-weight-lighter">
            In 1968, brothers Norman and Barry Sheffield opened Trident Studios
            London as a hub for innovation in music- an alternative to the ‘lab’
            mentality of rival Abbey Road. Attracting major, influential artists
            such as David Bowie, The Beatles, The Rolling Stones, Lou Reed,
            Elton John and Queen. Trident was the World’s first 8 Track Studio.
          </p>

          <p class="font-weight-lighter">
            Trackd founder Russell Sheffield and his brother, Justin, are heirs
            to Trident’s history.
          </p>

          <p class="font-weight-lighter">
            When Trident Studios was founded in 1968, it became home for some of
            the greatest artists of all time. Within the sound proofed walls of
            the Soho studio the Sheffield brothers created a space that became a
            melting pot of talent. A go to spot for up and coming musicians
            laying down enduringly brilliant songs.
          </p>

          <img
            src="../../assets/images/Norman_and_Barry_Sheffield_Trident_Studios_open.original.jpg"
            class="img-fluid"
            alt="Barry & Normal Sheffield"
          />

          <p>
            <small class="small font-weight-lighter mt-2 mb-3"
              >Norman and Barry Sheffield at the opening of Trident Studios, 8th
              March 1968.</small
            >
          </p>

          <p class="font-weight-lighter">
            The Sheffield brothers’ relaxed studio attitude but emphasis on high
            standard audio recording and a cutting edge approach to audio
            engineering meant that more than just amazing music was made in this
            St. Anne’s court studio.Trident was also the foundry for the
            legendary A Range Recording Console.
          </p>

          <p class="font-weight-lighter">
            From launch Trident proved a pull for recording artists, drawing The
            Beatles from Abbey Road to record ‘Hey Jude’. Not only using the
            state of the art technology, but also Trident’s famed studio piano,
            a handmade C. Bechstein concert sized instrument, which can also be
            heard on Elton John’s ‘Your song’ and ‘Life On Mars’ by David Bowie.
          </p>

          <img
            src="../../assets/images/The_Beatles_Hey_Jude_Trident_Studios_Small_Imag.original.jpg"
            class="img-fluid"
            alt="The Beatles recording Hey Jude at Trident Studios in 1968."
          />

          <p>
            <small class="small font-weight-lighter mt-2 mb-4"
              >The Beatles recording Hey Jude at Trident Studios in 1968.</small
            >
          </p>

          <p class="font-weight-lighter">
            Trident’s culture was more than skin deep. In 1972 a young band
            called Smile with a great lead singer were struggling to build a
            career. Norman and Barry took a chance, the result, the
            subjectively, greatest band of all time; Queen.
          </p>

          <img
            src="../../assets/images/Russell_Queen_and_Mott_The_Hoople_141273.original.jpg"
            class="img-fluid"
            alt="Russell Sheffield (bottom left), with Queen and Mott The Hoople in December 1973. Queen were relatively unknown then."
          />

          <p>
            <small class="small font-weight-lighter mt-2 mb-3"
              >Russell Sheffield (bottom left), with Queen and Mott The Hoople
              in December 1973. Queen were relatively unknown then.</small
            >
          </p>

          <p class="font-weight-lighter">
            Cut to now - making music is a digital world. Bedroom artists are
            seeing more opportunities to record, collaborate and share their
            work worldwide, with the ability to work independently free from
            historic constraints.
          </p>

          <p class="font-weight-lighter">
            Trackd captures the true spirit of collaboration, the ability for a
            guitarist from Nashville to record with vocals from Stockholm,
            throwing in a drum solo from Lisbon for good measure. Trackd
            provides our artists the ability to share ideas around the world,
            engaging with fans and listeners all in one place.
          </p>

          <p class="font-weight-lighter">
            Like you, our passion for music runs deep. We’ve built a mega cast
            of movers and shakers. Do’ers. And we’re on your team. Together the
            future of music is in our hands.
          </p>
        </b-col>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Company",
  data() {
    return {
      sections: [],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
p {
  font-size: 1.2em;
}

.image-backdrop {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
